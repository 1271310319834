.polymofund-select-search {
  .select-search {
    width: 100%; 
    height: 28px;
  }
}
.voter {
  width: 200px;
  height: 48px;
  .select-search {
    width: 100%; 
    height: 100%;
  }
}