.polyfund-view-transaction-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .polyfund-view-transaction-main {
    padding: 60px;

    .title {
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      display: flex;
      justify-content: space-between;
    }

    .transaction-content {
      margin-top: 20px;
      width: 100%;
      display: flex;
      .query-table {
        flex: 1;
        margin-left: 40px;
        overflow: hidden;
    
        .table-content {
          // border: 1px solid rgb(226, 232, 240);
          border-radius: 12px;
          padding: 12px 12px 12px 12px;
          overflow: hidden;
          overflow-x: scroll;
        }
    
        .switch-campaign-pagination {
          margin-top: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .table-content {
      flex: 1;
      margin-left: 40px;
    }

    .view-transaction-pagination {
      margin-top: 7px;
      margin-bottom: 20px;
    }
  }
}
