// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.polyfund-home-page {
  height: 100%;

  .polyfund-home-main {
    .polyfund-home-content {
      width: 1200px !important;
    }
  }
  
  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .polyfund-home-main {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;

    .polyfund-home-content {
      min-width: 600px;
      // width: 600px;
      margin-top: 82px;
      font-weight: 400;
      font-family: Inter;

      .content-title {
        width: 100%;
        text-align: center;

        .name {
          height: 58px;
          font-size: 48px;
          line-height: 58px;

          .icon1 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: 20px;
          }

          .icon2 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: -6px;
          }
        }
      }

      .content-des {
        margin-top: 71px;
        color: rgb(0, 0, 0);
        font-size: 20px;
        display: flex;
        flex-wrap: wrap;
        // line-height: 24px;
        .info {
          width: 25%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          // position: relative;
          // text-align: center;

          .pic {
            width: 200px;

            img {
              width: 200px;
            }
          }

          .feature-icon {
            color: #ffffff;
            transition: transform 0.3s ease;
            margin-right: 100px;
            margin-top: 0px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .icon {
              font-size: 30px;
              color: #283A53;
              animation: pulse 2s infinite;
            }

          }

          .info-content {
            padding: 16px;
            flex: 1;
            // display: flex;
            align-items: center;
            border: 1px solid #0000001a;
            border-radius: 8px;
            box-shadow: 0 1px 2px 0 #0000000f, 0 1px 3px 0 #0000001a;
            margin: 0 6px;
          }

          .title {
            padding-top: 6px;
            font-size: 24px;
            font-weight: 600;
            // color: #FFBC08;
            // color: #283a53;
          }

          // .title1 {
          //   // color: #FFBC08;
          // }

          .content {
            flex: 1;
            // margin-bottom: 20px;
            font-weight: 400;
            // color: #283a53;
            margin-top: 6px;
            // word-wrap: break-word;
            // text-wrap: no-wrap;
          }
        }

        .info-reserve {
          .content {
            margin-left: 0px;
            margin-right: 100px;
          }
        }
      }
      .content-campaign {
        justify-content: center;
      }

      .operate-content {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;

        .operate-btn {
          padding: 0;
          display: inline-block;
          width: 200px;
          height: 77px;
          line-height: 77px;
          text-align: center;
          border-radius: 20px;
          font-weight: 600x;
          font-size: 20px;
        }
      }
    }
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}