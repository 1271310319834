.polyfund-campaign-learn-mobile-page {
  min-height: calc(100vh - 0px);
  background: #283A53;

  .polyfund-campaign-mobile-main {

    padding: 12px;

    .header {
      font-size: 30px;
      color: #FFBC08;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
    }

    .desc {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      padding: 10px;
      margin-bottom: 10px;

      .text {
        margin-top: 6px;
      }

      .textT {
        margin-top: 18px;
      }
    }

    .fundDes {
      color: #fff;
      font-size: 20px;
      margin-top: 10px;
      line-height: 24px;
    }

    .cardItemLearn {
      width: '100%';
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      padding-bottom: 40px;
      margin-top: 20px;
      border-top-color: #FFBC08;
      border-top-width: 5px;
      position: relative;

      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .des {
        margin-top: 6px;
        margin-bottom: 18px;
        line-height: 20px;
      }

      .bottomContent {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}