.join-page {
  width: 416px;

  .join-name-pic {
    height: 54px;
    line-height: 54px;
    margin-bottom: 14px;

    .pic {
      display: inline-block;
      width: 54px;
      height: 54px;
    }

    .name {
      margin-left: 25px;
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .join-form {
    .name {
      margin-top: 12px;
    }
    .join-operate {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
    }
  }
}