.upload-file-content {
  width: 430px;
  height: 304px;
  box-sizing: border-box;
  background: rgba(178, 195, 218, 0.2);
  border: 1px dashed rgb(178, 195, 218);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-icon {
    margin-top: 98px;
    width: 42px;
    height: 42px;
    background: url('../../assets/img/donorData/upload-file.svg') 100% 100% no-repeat;
  }

  .upload-assets {
    margin-top: 31px;
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .upload-btn {
    margin-top: 22px;
    width: 145px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    background: rgb(252, 163, 17);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
}

.upload-file-progess-content {
  width: 482px;
  height: 166px;
  background: rgba(178, 195, 218, 0.2);
  border: 1px solid rgb(178, 195, 218);
  border-radius: 8px;
  padding: 18px 24px;

  .uploading {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  .upload-progess {
    margin-top: 21px;
    width: 100%;
    height: 69px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 12px 18px;
    display: flex;

    .file-icon {
      border: 1px dashed #ccc;
      width: 56px;
      height: 48px;
      margin-right: 6px;
      background: url('../../assets/img/donorData/file.svg') 100% 100% no-repeat;
      background-size: 100% 100%;
    }
    
    .file-progess {
      flex: 1;

      .file-name {
        margin-top: 6px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
      }

      .progress {
        margin-top: 8px;
        width: 100%;
      }

    }

    .file-content {
      width: 100%;
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
}