.polyfund-account-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .polyfund-account-main {
    padding: 43px 135px 0 123px;

    .account-header {
      font-family: Inter;
      display: flex;
      justify-content: space-between;

      .team-name {
        color: rgb(0, 0, 0);
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
      }

      .delete-btn {
        cursor: pointer;
        height: 24px;
        line-height: 22px;
        padding: 0 8px;
        border: 1px solid rgb(229, 62, 62);
        border-radius: 6px;
        color: rgb(229, 62, 62);
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        &:hover {
          background: #FADBDB;
        }
      }
    }

    .card-content {
      padding: 34px 22px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      /* base */
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .message-content {
      margin-top: 47px;
      padding: 41px 32px;
      .message-con {
        display: flex;
        justify-content: space-between;
        font-family: Inter;
        font-weight: 400;
        .text {
          color: rgb(0, 0, 0);
          font-size: 24px;
          display: flex;
          align-items: center;
        }
        .mes-btn {
          height: 40px;
          line-height: 40px;
          padding: 0 16px;
          border: 1px solid rgb(61, 90, 128);
          border-radius: 6px;
          color: rgb(61, 90, 128);
          font-size: 600;
          cursor: pointer;
        }
      }
    }
    .transactions-content {
      margin-top: 32px;
      .transcation-btn {
        margin-left: 8px;
        display: flex;
        align-items: center;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
        background: rgb(252, 163, 17);
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    .table-content {
      margin-top: 32px;
      margin-bottom: 37px;

      .team-member {
        margin-left: 8px;
        margin-bottom: 34px;
        color: rgb(71, 71, 71);
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        .operate {
          display: flex;
        }
        .invite-btn {
          margin-left: 8px;
          display: flex;
          align-items: center;
          height: 24px;
          line-height: 24px;
          padding: 0 8px;
          background: rgb(252, 163, 17);
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          border-radius: 6px;
          cursor: pointer;

          img {
            width: 13px;
            height: 13px;
            margin-right: 5px;
          }
        }

      }

      .account-pagination {
        margin-top: 32px;
        margin-bottom: 42px;
      }
    }
  }
  .sign-operate {
    position: relative;
    top: 63px;
    display: flex;
    justify-content: flex-end;
  }
  
}
.operate-container {
  .operate-hover-delete {
    height: 21px;
    display: none;
  }
  &:hover {
    .operate-delete {
      display: none;
    }
    .operate-hover-delete {
      display: inline-block;
    }
  }
}
.tooltip-content {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltip-des {
    width: 360px;
    background: #ddd;
    color: #000;
    font-family: Inter;
    display: none;
    position: absolute;
    top: 0px;
    left: 40px;
    border-radius: 8px;
    padding: 6px 12px;
    z-index: 1;
    font-size: 16px;
  }
  &:hover {
    .tooltip-des {
      display: inline-block;
    }
  }
  
}