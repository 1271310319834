.operate-in-up {
  .sign {
    display: inline-block;
    width: 114px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: rgb(61, 90, 128);
    border-radius: 20px;
    margin-left: 24px;
    z-index: 100;
  }
}

.navbar-content {
  .page-name {
    cursor: pointer;
    display: inline-block;
  }
  .navbar-item {
    display: inline-block;
    padding: 0 16px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-family: Inter;
  }
}
@media screen and (max-width: 500px) {
  .navbar-content {
    .page-name {
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
    }
    .navbar-item {
      display: none;
    }
  }
}
@media screen and (max-width: 1200px) {
  .nav-bar-t {
    position: auto;
  }
}
