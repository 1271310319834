.polymofund-main {
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .polymofund-content {
    min-height: calc(100vh - 97px);
    background: #fff;
  }

  .polymofund-footer {
    width: 100%;
    height: 97px;
    background: rgb(61, 90, 128);
    line-height: 97px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    z-index: 100;
    .polymofund-footer-link {
      margin-left: 10px;
      cursor: pointer;
      border-bottom: 1px solid #fff;
    }
  }
}

@media screen and (max-width: 500px) {
  .polymofund-main {
    min-width: auto;
    .polymofund-content {
      min-height: calc(100vh - 60px);
    }
    .polymofund-footer {
      display: none;
    }
  }
}
