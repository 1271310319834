.polyfund-home-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .polyfund-home-main {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    .polyfund-home-content {
      width: 1000px;
      margin-top: 82px;
      font-weight: 400;
      font-family: Inter;
  
      .content-title {
        width: 100%;
        text-align: center;
  
        .name {
          height: 58px;
          font-size: 48px;
          line-height: 58px;
          .icon1 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: 20px;
          }
          .icon2 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: -6px;
          }
          .text {
            color:#FFBC0A;
          }
        }
      }
      
      .downloadList {
        display: flex;
        justify-content: space-around;
        .downloadItem {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: bold;
          }
          img {
            width: 300px;
          }
        }
      }
    }
  }
}