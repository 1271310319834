.polyfund-campaign-mobile-page {
  min-height: calc(100vh - 0px);
  background: #283A53;
  .polyfund-campaign-mobile-main {
    
    padding: 12px;
    .header {
      font-size: 30px;
      color: #FFBC08;
      font-weight: 700;
      padding-left: 10px;
    }
    .desc {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      padding: 10px;
      margin-bottom: 10px;
      .text {
        margin-top: 6px;
      }
      .textT {
        margin-top: 18px;
      }
    }
    

    .cardItem {
      width: '100%';
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      padding-bottom: 60px;
      margin-top: 10px;
      border-top-color: #FFBC08;
      border-top-width: 5px;
      position: relative;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .des {
        margin-top: 6px;
      }
      .bottomContent {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .download {
          background: #283A53;
          color: #fff;
          border-radius: 20px;
          width: 162px;
          text-align: center;
          height: 40px;
          line-height: 40px;
          font-weight: bold;
          font-size: 16px;
        }
        .learnMore {
          font-weight: bold;
        }
      }
    }
  }
}