.home-page {
  width: 100%;
  height: 100%;
  font-family: Inter;

  .content {
    height: 405px;
    background: rgb(40, 58, 83);

    .content-des {
      width: 100%;
      height: 210px;
      padding: 56px 160px 0;
      color: #fff;

      .des-title {
        font-family: Inter;
        font-size: 48px;
        font-weight: 400;
        line-height: 58px;
      }

      .des-text {
        width: 100%;
        margin-top: 32px;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        font-family: Inter;
      }

      .des-text-b {
        margin-top: 40px;
      }
    }

    .sign-up {
      cursor: pointer;
      width: 116px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      display: inline-block;
      background: rgb(255, 188, 10);
      border-radius: 6px;
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      margin-top: 32px;
    }
  }

  .middle-line {
    width: 100%;
    height: 32px;
    background: #FFBC0A;
  }

  .content-info {
    position: relative;
    top: -67px;
    width: 957px;
    display: flex;
    justify-content: space-between;
    margin: auto;

    .card-info {
      width: 422px;
      height: 294px;
      background: #fff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 36px 45px 0 32px;
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-weight: 400;

      .title {
        font-size: 32px;
        line-height: 38px;
      }

      .des {
        margin-top: 16px;
        font-size: 18px;
        line-height: 22px;
        font-family: Inter;
      }
      .nosign-operate {
        // margin-top: 66px;
        cursor: pointer;
        width: 121px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 600;
        background: #283A53;
        color: #fff;
        border-radius: 20px;
      }
      .sign-operate {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        margin-top: 100px;
        .subscribe {
          cursor: pointer;
          padding: 0 16px;
          // width: 121px;
          text-align: center;
          height: 40px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          background: #283A53;
          color: #fff;
          border-radius: 20px;
        }
        .learn-more {
          cursor: pointer;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    .card-info-wait {
      // color: #5F5F5F;
      .learn-more{
        color: #000;
      }
    }
  }
}