.polyfund-campaign-learn-mobile-page {
  min-height: calc(100vh - 0px);
  background: #283A53;

  .polyfund-campaign-mobile-main {

    padding: 12px;

    .header {
      font-size: 30px;
      color: #FFBC08;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
    }

    .desc {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      padding: 10px;
      margin-bottom: 10px;

      .text {
        margin-top: 6px;
      }

      .textT {
        margin-top: 18px;
      }
    }


    .cardItem {
      width: '100%';
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      padding-bottom: 40px;
      margin-top: 20px;
      border-top-color: #FFBC08;
      border-top-width: 5px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }

      .img {
        width: 100%;
      }
    }
  }
}