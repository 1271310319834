.polyfund-contact-us-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .polyfund-contact-us-main {
    width: 100%;
    display: flex;
    justify-content: center;
    .polyfund-contact-us-content {
      width: 586px;
      height: 366px;
      margin-top: 176px;
      font-weight: 400;
      font-family: Inter;
  
      .content-title {
        width: 100%;
        text-align: center;
  
        .name {
          height: 58px;
          font-size: 48px;
          line-height: 58px;
          .text {
            color:#FFBC0A;
          }
        }
      }
  
      .contact-us-content {
        margin-top: 114px;
        width: 100%;
        display: flex;
        justify-content: center;
        .contect-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: rgb(0, 0, 0);
            font-family: Inter;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
          .pic {
            height: 63px;
          }
          .title {
            margin-top: 20px;
            font-weight: 600;
          }
          .method {
            margin-top: 14px;
          }
        }
      }
    }
  }
}