.input-file{
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: auto;
  height: 40px;

  background-color: #283A53;
  border: solid 1px #ddd;
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  color:#fff;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  z-index: 10;
}
.input-file+input[type="file"] {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  font-size: 14px;
  background-color: #fff;
  transform: translate(-300px, 0px) scale(4);
  height: 32px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-name {
  position: relative;
  top: -14px;
}
