.polyfund-service-plan-page {
  height: 100%;
  padding-bottom: 50px;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .polyfund-service-plan-main {
    width: 100%;
    display: flex;
    justify-content: center;
    .polyfund-service-plan-content {
      width: 70%;
      margin-top: 60px;
      font-weight: 400;
      font-family: Inter;
  
      .content-title {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgb(40, 58, 83);
        padding-bottom: 20px;
        .name {
          font-size: 32px;
          line-height: 32px;
          .text {
            color:#FFBC0A;
          }
        }
        .des {
          height: 32px;
          // margin-top: 10px;
          // font-size: 28px;
          // color: #000;
        }
      }
  
      .content-c {
        display: flex;
        justify-content: center;
        margin-top: 60px;
      }

      .fee {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
        color: #000;
        font-weight: 600;

        .refresh-btn{
          padding: 0 6px;
          cursor: pointer;
        }
      }

      .foot-c {
        margin-top: 20px;
        font-size: 24px;

      }
    }
  }
}