
input, select {
  font-family: 'Inter' !important;
}
.input-h48 {
  // border: 1px solid rgb(61, 90, 128);
  height: 48px !important;
  .chakra-input__left-element {
    height: 48px !important;
    line-height: 48px !important;
  }
  // &:focus{
  //   border: none;
  // }
}


.input-h32 {
  height: 32px !important;
  .chakra-input__left-element {
    height: 32px !important;
    line-height: 32px !important;
  }
}

.form-flex {
  .form-control {
    margin-top: 16px;
    .control {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .label {
        min-width: 150px !important;
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        margin-right: 0;
      }
    }
    .error-mes {
      padding-left: 150px;
    }
  }
}

.operate-btn {
  cursor: pointer;
  display: inline-block;
  padding: 5px 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(255, 255, 255);
  background: rgb(40, 58, 83);
  border-radius: 6px;
}
.chakra-slider__thumb {
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
}
.chakra-checkbox__control, .chakra-radio__control, .chakra-slider__thumb {
  box-shadow: none !important;
}
.custom-table {
  thead{
    font-size: 12px;
    td {
      padding: 12px 24px !important;
    }
  }
  tbody {
    tr {
      td {
        padding: 8px 24px !important;
      }
    }
    font-size: 14px;
    color: rgb(45, 55, 72);
    .tag-item {
      display: inline-block;
      text-align: center;
      min-width: 41px;
      padding: 1px 11px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      border-radius: 2px;
    }
    .tag-item-yes {
      background: #C6F6D5;
      color: rgb(34, 84, 61);
    }
    .tag-item-no {
      background: #FED7D7;
      color: #822727;
    }
    .tag-item-source {
      display: inline-block;
      text-align: center;
      min-width: 41px;
      padding: 1px 11px;
      background: #C6F6D5;
      color: rgb(42, 67, 101);
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      border-radius: 10px;
    }
    .tag-item-source-private {
      background: #FEEBCB;
    }
    .tag-item-source-polymo {
      background: #BEE3F8;
    }
  }
}
.ant-select-dropdown, .ant-picker-dropdown {
  z-index: 1500;
}
.custon-switch .ant-switch-checked {
  background: #3AB795 !important;
}

.search-data-query {
  width: 240px;

  .query-title {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .query-content {
    max-height: 500px;
    overflow-y: scroll;
  }

  .search-value {
    display: flex;
    justify-content: space-between;
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    position: relative;
    top: 15px;

  }

  .search-label {
    position: relative;
    left: 0;

    .pic-top {
      min-width: 12px;
      background: url('../assets/img/donorData/bg.svg') 100% 100% no-repeat;
      background-size: 100% 100%;
      color: #fff;
      position: relative;
      top: -18px;
      font-size: 8px;
      padding: 5px 4px;
      text-align: center;
      vertical-align: text-top;
      height: 25px;
    }
  }

  .search-operate {
    margin-top: 30px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: space-around;

    .search-btn {
      cursor: pointer;
      text-align: center;
      width: 80px;
      height: 31px;
      line-height: 31px;
      padding: 0px 8px 0px 8px;
      box-sizing: border-box;
      border: 1px solid rgb(51, 133, 255);
      border-radius: 6px;
    }

    .search-btn-clear {
      color: rgb(51, 133, 255);
    }

    .search-btn-apply {
      background: rgb(51, 133, 255);
      color: #fff;
    }
  }
}

.apexcharts-legend {
  overflow: hidden !important;
  overflow-y: scroll !important;
  .apexcharts-legend-series {
    width: 120px !important;
  }
}

.disabledBtn {
  border: 1px solid #ccc !important;
  color: #ccc !important;
  cursor: no-drop !important;
}
.disabledBtnP {
  background: #ccc !important;
  cursor: no-drop !important;
}

.user-name-default {
  width: 40px;
  height: 40px;
  background: #11047A;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 50%;
}

::-webkit-scrollbar{
	// display:none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  // background-color: red;
}

::-webkit-scrollbar-track-piece {
  // background: yellow;
}

::-webkit-scrollbar-thumb {
  background: rgba(40, 58, 83, 0.5);
  border-radius: 4px;
}

.chakra-modal__overlay {
  width: 100% !important;
  height: 100% !important;
}
.chakra-modal__content-container {
  width: 100% !important;
}
.ant-spin-fullscreen {
  width: 100%;
  height: 100%;
}

.default-el-table {
  tbody tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-popover {
  z-index: 10001 !important;
}

.antPrimaryBtn {
  margin-left: 12px;
  width: 300px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 16px;
  background: rgb(40, 58, 83);
  background-color:rgb(40, 58, 83); 
  cursor: pointer;
  font-family: Inter;
  padding: 0;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  span{
    border-radius: 6px;
    width: 100%;
    height: 100%;
    background-color:rgb(40, 58, 83);
    :hover {
      height: 100%;
      border-radius: 6px;
    }
  }
  :hover {
    // height: 40px;
    line-height: 36px;
    color: #fff;
    border-radius: 6px;
    background: rgb(40, 58, 83);
    border: 2px solid #5BA4FA;
    background-color:rgb(40, 58, 83);
  }
}


.antDeleteBtn {
  margin-left: 12px;
  width: 120px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 16px;
  background: #E53E3E;
  background-color:#E53E3E; 
  cursor: pointer;
  font-family: Inter;
  padding: 0;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  span{
    border-radius: 6px;
    width: 100%;
    height: 100%;
    background-color:#E53E3E;
    :hover {
      height: 100%;
      border-radius: 6px;
    }
  }
  :hover {
    // height: 40px;
    color: #fff;
    border-radius: 6px;
    background: #D71D1D;
  }
}
.antBtnConfirm {
  width: 40px;
  border-radius: 6px;
  background: rgb(40, 58, 83);
  padding: 0 !important;
  border: none;
  span{
    display: inline-block;
    width: 100%;
    border-radius: 6px;
    background-color:rgb(40, 58, 83);
    :hover {
      width: 100%;
      border-radius: 6px;
      background-color:rgb(40, 58, 83);
    }
  }
  :hover {
    background: rgb(40, 58, 83);
  }
}