.polyfund-switch-campaign-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .polyfund-switch-campaign-main {
    padding: 60px;

    .title {
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      display: flex;
      justify-content: space-between;
    }

    .switch-content {
      margin-top: 12px;
      width: 100%;
      display: flex;
    
      .query-table {
        flex: 1;
        margin-left: 40px;
        overflow: hidden;
    
        // .selectAll {
        //   display: none;
        // }
    
        .table-content {
          // border: 1px solid rgb(226, 232, 240);
          border-radius: 12px;
          padding: 12px 12px 12px 12px;
          overflow: hidden;
          overflow-x: scroll;
        }
    
        .switch-campaign-pagination {
          margin-top: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .operate-container {
    .operate-hover-delete {
      display: none;
      height: 21px;
    }
    &:hover {
      .operate-delete {
        display: none;
      }
      .operate-hover-delete {
        display: inline-block;
      }
    }
  }
}

.donor-detail-content {
  display: flex;
  width: 100%;

  .detail-left {
    width: 581px;
    margin-right: 107px;

    .detail-left-top {
      width: 100%;
      display: flex;

      .detail-left-top-data {
        flex: 1;
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;

        p {
          margin-bottom: 14px;
        }

        span {
          margin-left: 8px;
          font-weight: 400;
        }
      }

      .detail-left-top-operate {
        width: 120px;

        .custon-switch-name {
          color: rgb(0, 0, 0);
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          margin-right: 12px;
        }

        .operate-send-btn {
          width: 103px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          box-sizing: border-box;
          border: 1px solid rgb(61, 90, 128);
          border-radius: 6px;
          color: rgb(61, 90, 128);
          font-family: Inter;
          font-size: 10px;
          font-weight: 600;
          line-height: 16px;
          margin-top: 10px;
          &:hover {
            border: 1px solid #5BA4FA;
          }
        }
      }
    }

    .detail-left-bot {
      margin-top: 40px;
      width: 100%;

      .table-title {
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 13px;
      }
    }
  }

  .detail-right {
    flex: 1;

    .card {
      border: 0.1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .detail-right-polarity {
      height: 69px;
      padding: 12px 18px;

      .title {
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
      }

      .polarity-content {
        margin-top: 12px;
        width: 380px;
        height: 10px;
        background: skyblue;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
      }
    }

    .detail-right-category {
      margin-top: 16px;
      height: 207px;
      padding: 12px 18px;
      ::-webkit-scrollbar{
        display:none;
      }
      .title {
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
      }

      .apexcharts-legend-marker {
        top: 2px !important;
      }    
    }

    .donor-note {
      margin-top: 34px;
      // height: 361px;
      border-radius: 8px;
      background: rgb(237, 242, 247);
      padding: 16px 10px;
      display: flex;
      flex-direction: column;

      .add-note {
        position: relative;

        .send-note-btn {
          position: absolute;
          right: 12px;
          top: 11px;
          z-index: 1;
          cursor: pointer;
        }
      }

      .note-content {
        margin-top: 3px;
        width: 100%;
        flex: 1;
        height: 300px;
        overflow: hidden;
        overflow-y: scroll;

        .note-item {
          margin-top: 12px;
          border-radius: 8px;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
          padding: 8px 12px;
          background: #fff;
          width: 100%;

          .note-userinfo {
            display: flex;
            .userinfo {
              margin-left: 8px;
            }
            .name {
              color: rgb(0, 0, 0);
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
            }

            .time {
              color: rgb(103, 103, 103);
              font-family: Inter;
              font-size: 10px;
              font-weight: 400;
            }
          }

          .note-des {
            color: rgb(0, 0, 0);
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            padding-left: 48px;
          }
        }
      }
    }

    .service-un-img {
      width: 100%;
      height: 100%;
    }
  }
  .donor-detail-pagination {
    margin-top: 20px;
  }
  .service-unavaliable {
    text-align: center;
    font-family: 'Inter';
  }
  .apexcharts-legend.apx-legend-position-left {
    overflow: hidden;
    top: 8px !important;
  }
}

@media screen and (max-width: 500px) {
  .polyfund-switch-campaign-page {
  
    .polyfund-switch-campaign-main {
      padding: 12px;
  
      .title {
        font-size: 16px;
        .operate-content {
          display: none;
        }
      }
  
      .switch-content {
      
        .query-table {
          margin-left: 0px;
      
          .table-content {
            // border: 1px solid rgb(226, 232, 240);
            border-radius: 0px;
            padding: 12px 0;
            overflow: hidden;
            overflow-x: scroll;
          }
        }
      }
    }
  }
  .search-data-query {
    display: none;
  }
  .donor-detail-content {
    display: inline-block;
    width: 100%;
  
    .detail-left {
      width: 100%;
      margin-right: 107px;
  
      .detail-left-top {
        width: 100%;
        display: flex;
  
        .detail-left-top-data {
          flex: 1;
          color: rgb(0, 0, 0);
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
  
          p {
            margin-bottom: 14px;
          }
  
          span {
            margin-left: 8px;
            font-weight: 400;
          }
        }
  
        .detail-left-top-operate {
          width: 120px;
  
          .custon-switch-name {
            color: rgb(0, 0, 0);
            font-family: Inter;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            margin-right: 12px;
          }
  
          .operate-send-btn {
            width: 103px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            box-sizing: border-box;
            border: 1px solid rgb(61, 90, 128);
            border-radius: 6px;
            color: rgb(61, 90, 128);
            font-family: Inter;
            font-size: 10px;
            font-weight: 600;
            line-height: 16px;
            margin-top: 10px;
            &:hover {
              border: 1px solid #5BA4FA;
            }
          }
        }
      }
  
      .detail-left-bot {
        margin-top: 40px;
        width: 100%;
  
        .table-title {
          color: rgb(0, 0, 0);
          font-family: Inter;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 13px;
        }
      }
    }
  
    .detail-right {
      flex: 1;
  
      .card {
        border: 0.1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
      }
  
      .detail-right-polarity {
        display: none;
      }
  
      .detail-right-category {
        display: none;
      }
  
      .donor-note {
        margin-top: 34px;
        height: 361px;
        border-radius: 8px;
        background: rgb(237, 242, 247);
        padding: 16px 10px;
        display: flex;
        flex-direction: column;
  
        .add-note {
          position: relative;
  
          .send-note-btn {
            position: absolute;
            right: 12px;
            top: 11px;
            z-index: 1;
            cursor: pointer;
          }
        }
  
        .note-content {
          margin-top: 3px;
          width: 100%;
          flex: 1;
          overflow: hidden;
          overflow-y: scroll;
  
          .note-item {
            margin-top: 12px;
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            padding: 8px 12px;
            background: #fff;
            width: 100%;
  
            .note-userinfo {
              display: flex;
              .userinfo {
                margin-left: 8px;
              }
              .name {
                color: rgb(0, 0, 0);
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
              }
  
              .time {
                color: rgb(103, 103, 103);
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
              }
            }
  
            .note-des {
              color: rgb(0, 0, 0);
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 150%;
              padding-left: 48px;
            }
          }
        }
      }
  
      .service-un-img {
        width: 100%;
        height: 100%;
      }
    }
    .donor-detail-pagination {
      margin-top: 20px;
    }
    .service-unavaliable {
      text-align: center;
      font-family: 'Inter';
    }
    .apexcharts-legend.apx-legend-position-left {
      overflow: hidden;
      top: 8px !important;
    }
  }
}