.pagination-content {
  // padding-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-text {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .page-number {
    display: inline-block;
    text-align: center;
    width: 60px;
    height: 31px !important;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(61, 90, 128);
    border-radius: 2px;
    margin: 0 8px;
  }

  .of-text,
  .total-text {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-right: 4px;
  }

  .operate-btn {
    background: rgb(40, 58, 83);;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 21px;
    margin: 0 7px;
  }
}