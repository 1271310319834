.change-password-page {
  width: 416px;
  
  .change-password-form {
    .name {
      margin-top: 12px;
    }
    .change-password-operate {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
    }
  }
}