.create-book-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .create-book-main {
    width: 100%;
    display: flex;
    justify-content: center;

    .create-book-content {
      width: 586px;
      height: 366px;
      margin-top: 182px;
      font-weight: 400;
      font-family: Inter;

      .content-title {
        width: 100%;
        text-align: center;

        .name {
          height: 58px;
          font-size: 48px;
          line-height: 58px;
        }
      }


      .operate-content {
        margin-top: 71px;
        width: 100%;
        display: flex;
        justify-content: center;

        .operate-btn {
          border-radius: 20px;
          padding: 26px 32px;
        }
      }

      .go-back {
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

}

.add-donor-data-content {
  width: 100%;
  display: flex;

  .query-table {
    flex: 1;
    margin-left: 40px;
    overflow: hidden;

    // .selectAll {
    //   display: none;
    // }

    .table-content {
      // border: 1px solid rgb(226, 232, 240);
      border-radius: 12px;
      padding: 12px 12px 12px 12px;
      overflow: hidden;
      overflow-x: scroll;
    }

    .donor-pagination {
      margin-top: 30px;
    }
  }
}

.upload-csv-content {
  display: flex;
  justify-content: center;
  margin-top: 67px;
  margin-bottom: 67px;
}

.match-column-content {
  padding: 24px 22px;

  .match-column-des {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  select {
    font-family: Inter;
  }
  .form-flex {
    margin-top: 16px;
  }
}