.all-team-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .all-team-main {
    width: 100%;
    display: flex;
    justify-content: center;

    .all-team-content {
      width: 1184px;
      margin-top: 43px;
      font-weight: 400;
      font-family: Inter;

      .content-title {
        width: 100%;
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
      }

      .team-contents {
        margin-top: 50px;
        width: 100%;
        // height: 433px;
        max-height: 433px;
        border-radius: 8px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        padding: 0 11px;
        margin-bottom: 50px;

        .team-contents-title {
          height: 102px;
          line-height: 102px;
          display: flex;
          justify-content: space-between;
          padding: 0 15px 0 36px;

          .title {
            font-family: Inter;
            font-size: 28px;
            font-weight: 600;

            .refresh-btn {
              margin-left: 8px;
              cursor: pointer;
            }
          }
        }

        .no-team {
          margin-top: 85px;
          padding-bottom: 20px;
          margin-left: 277px;
          font-family: Inter;
          font-size: 28px;
          font-weight: 400;
          line-height: 34px;

          .add-team {
            margin-top: 40px;

            .add-new-team {
              cursor: pointer;
              color: #3182CE;
            }
          }
        }

        .all-teams {
          // height: 330px;
          max-height: 330px;
          overflow-y: scroll;
          padding-bottom: 20px;

          .item-team {
            height: 65px;
            line-height: 65px;
            cursor: pointer;
            border-radius: 4px;
            padding-left: 36px;
            color: rgb(40, 58, 83);
            font-family: Inter;
            font-size: 28px;
            font-weight: 600;
            border-top: 1px solid rgb(229, 229, 229);

            &:hover {
              background: rgb(240, 240, 240);
            }
          }
        }
      }

    }
  }

}

.add-team-dialog-body {

  .add-team-dialog-content {
    padding: 52px 67px !important;
  }

  .search-team-content {
    margin-top: 5px;
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-weight: 400;

    .search-team-result {
      font-size: 14px;
      line-height: 17px;
      .tooltip {
        margin-left: 12px;
      }
    }

    .search-team-list {
      margin-top: 13px;
      max-height: 400px;
      overflow: hidden;
      overflow-y: scroll;

      .item {
        width: 100%;
        padding-left: 13px;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        cursor: pointer;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;

        &:hover {
          background: rgb(234, 234, 234);
        }
      }
    }
  }

  .search-team-empty {
    padding: 56px 143px !important;
    font-size: 14px;

    .no-team {
      font-size: 18px;
      margin-bottom: 12px;
    }

    .team-empty-btn {
      color: #3385FF;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 500px) {
  .all-team-page {
  
    .bg-line {
      height: 24px;
    }
  
    .all-team-main {
  
      .all-team-content {
        width: 100%;
        padding: 0 12px;
        margin-top: 20px;
  
        .content-title {
          font-size: 24px;
          line-height: 24px;
        }
  
        .team-contents {
          margin-top: 20px;
          max-height: 433px;
          border-radius: 8px;
          box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
          padding: 0 11px;
          margin-bottom: 50px;
  
          .team-contents-title {
            height: 60px;
            line-height: 60px;
            padding: 0 12px;
            .title {
              font-family: Inter;
              font-size: 22px;
  
              .refresh-btn {
                margin-left: 8px;
                cursor: pointer;
              }
            }
            .operate-content {
              display: none;
            }
          }
  
          .no-team {
            display: none;
          }
          .no-team-mobile {
            padding: 0 12px;
            padding-bottom: 60px;
            margin-top: 60px;
            color: rgb(40, 58, 83);
            font-weight: 600;
            .des {
              margin-bottom: 20px;
            }
          }
  
          .all-teams {
            // height: 330px;
            max-height: 330px;
            overflow-y: scroll;
            padding-bottom: 20px;
  
            .item-team {
              height: 36px;
              line-height: 36px;
              padding-left: 12px;
              font-size: 18px;
  
              &:hover {
                background: rgb(240, 240, 240);
              }
            }
          }
        }
  
      }
    }
  
  }
}
