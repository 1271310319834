.polyfund-switch-campaign-mobile-page {
  min-height: calc(100vh - 60px);

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }

  .polyfund-switch-campaign-mobile-main {
    padding: 12px;

    .header {
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      line-height: 29px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card {
      border: 1px solid #ccc;
      padding: 12px;
      border-radius: 8px;
    }

    .donor-detail-tip {
      margin-top: 12px;
      text-align: center;
      font-size: 12px;
      color: #ccc;
    }

    .donor-detail-name {
      margin-top: 12px;
      text-align: center;
      color: #283A53;
      font-size: 20px;
    }

    .detail-content {

      .detail-data {
        flex: 1;
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 14px;
        line-height: 17px;
        width: 100%;
        margin-top: 16px;

        .info-tit {
          color: rgb(40, 58, 83);
          font-weight: 600;
        }

        p {
          margin-bottom: 14px;
        }

        span {
          // margin-left: 8px;
          font-weight: 400;
        }

        .info-phone {
          margin-left: 6px;
          padding: 6px 12px;
          background: rgb(40, 58, 83);
          color: #fff;
          border-radius: 8px;
        }

      }

      .no-team-mobile {
        padding: 0 12px;
        padding-bottom: 60px;
        margin-top: 60px;
        color: rgb(40, 58, 83);
        font-weight: 600;

        .des {
          margin-bottom: 20px;
        }
      }

    }

    .detail-operate {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;

      .item {
        margin-bottom: 12px;
        width: 50%;
      }

      .custon-switch-name {
        display: inline-block;
        color: rgb(40, 58, 83);
        font-weight: 600;
        width: 86px;
      }
    }

    .operate-show-btn {
      margin-top: 20px;

      // .previous-btn {
      //   position: fixed;
      //   top: calc(50% - 20px);
      //   left: 8px;
      //   opacity: 0.6;
      // }
      // .next-btn {
      //   position: fixed;
      //   top: calc(50% - 20px);
      //   right: 8px;
      //   opacity: 0.6;
      // }
    }
  }

}
.donor-detail-history-dialog {
  .table-content {
    border-radius: 0px;
    padding: 12px 0;
    overflow: hidden;
    overflow-x: scroll;
  }
  .donor-detail-pagination {
    margin-top: 10px;
  }

}
.donor-note-content {
  width: 100%;

  .donor-note {
    width: 100%;
    margin-top: 34px;
    // height: 361px;
    border-radius: 8px;
    background: rgb(237, 242, 247);
    padding: 16px 10px;

    .add-note {
      position: relative;

      .send-note-btn {
        position: absolute;
        right: 12px;
        top: 11px;
        z-index: 1;
        cursor: pointer;
      }
    }

    .note-content {
      margin-top: 3px;
      width: 100%;
      // flex: 1;
      height: 300px;
      overflow: hidden;
      overflow-y: scroll;

      .note-item {
        margin-top: 12px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        padding: 8px 12px;
        background: #fff;
        width: 100%;

        .note-userinfo {
          display: flex;

          .userinfo {
            margin-left: 8px;
          }

          .name {
            color: rgb(0, 0, 0);
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
          }

          .time {
            color: rgb(103, 103, 103);
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
          }
        }

        .note-des {
          color: rgb(0, 0, 0);
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          padding-left: 48px;
        }
      }
    }
  }
}