.setting-page {
  height: 100%;
  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .setting-content {
    margin-top: 43px;
    padding: 0 123px;
    .setting-title {
      font-family: Inter;
      font-size: 32px;
      font-weight: 400;
      line-height: 38px;
    }
    .content {
      display: flex;
      .tab{
        margin-top: 24px;
        width: 160px;
        .tab-item {
          cursor: pointer;
          margin-top: 16px;
          color: rgb(95, 95, 95);
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
        }
        .active {
          color: rgb(0, 0, 0);
          font-weight: 700;
        }
      }
      .tab-content {
        margin-left: 25px;
        padding-top: 32px;
        flex: 1;
      }
    }
  }
}