.sign-in-page {
  height: calc(100vh - 97px);
  min-height: 700px;
  width: 100%;
  background: rgb(40, 58, 83);
  flex-direction: column;
  align-items: center;

  .sign-in-card {
    // margin: 219px auto;
    margin-top: 219px;
    width: 496px;
    padding: 40px 36px;
    background: rgb(255, 255, 255);
    /* base */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .title {
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 30px;
      font-weight: 400;
      line-height: 150%;
    }

    .sing-in-form {
      .name {
        margin: 12px 0 6px 0;
      }
      .error-text {
        color: #EE5D50;
      }
      .sign-operate {
        position: relative;
        top: 63px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
      }
    }

    .forget-password {
      position: relative;
      top: -60px;
    }
  }

  .to-sign-up {
    margin-top: 22px;
    color: rgb(255, 255, 255);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    .sign-up {
      cursor: pointer;
      border-bottom: 1px solid #fff;
    }
  }
}

@media screen and (max-width: 500px) {
  .sign-in-page {
    height: calc(100vh - 0px);
    width: 100%;
    padding: 0 20px;
  
    .sign-in-card {
      margin-top: 120px;
      width: 100%;
      padding: 20px 18px 40px 18px;
      
      .sing-in-form {
        .sign-operate {
          top: 30px;
        }
      }

      .forget-password {
        display: none;
      }
    }
  
    .to-sign-up {
      display: none;
    }
  }
}