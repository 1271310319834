.polyfund-home-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .polyfund-home-main {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    .polyfund-home-content {
      width: 1000px;
      margin-top: 82px;
      font-weight: 400;
      font-family: Inter;
  
      .content-title {
        width: 100%;
        text-align: center;
  
        .name {
          height: 58px;
          font-size: 48px;
          line-height: 58px;
          .icon1 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: 20px;
          }
          .icon2 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: -6px;
          }
          .text {
            color:#FFBC0A;
          }
        }
      }
      .card-info {
        margin-top: 20px;
        width: 100%;
        // height: 294px;
        background: #fff;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 2px 1px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 30px;
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-weight: 400;
  
        .title {
          font-size: 32px;
          line-height: 38px;
        }
  
        .des {
          margin-top: 16px;
          font-size: 18px;
          line-height: 22px;
          font-family: Inter;
          text-align: justify;
        }
      }
    }
  }
}