.polyfund-pricing-page {
  height: 100%;
  padding-bottom: 50px;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .polyfund-pricing-main {
    width: 100%;
    display: flex;
    justify-content: center;
    .polyfund-pricing-content {
      width: 70%;
      margin-top: 60px;
      font-weight: 400;
      font-family: Inter;
  
      .content-title {
        width: 100%;
        text-align: center;
        
        .name {
          font-size: 32px;
          line-height: 32px;
          .text {
            color:#FFBC0A;
          }
        }
        .des {
          height: 32px;
          // margin-top: 10px;
          // font-size: 28px;
          // color: #000;
        }
      }
      
      .service-plan-title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
      }
      .sms-title {
        margin-top: 30px;
      }
      .pricing-content {
        margin-top: 20px;
        padding-bottom: 30px;
        border-top: 1px solid rgb(40, 58, 83);
        width: 100%;
        display: flex;
        justify-content: center;
        .contect-item {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: rgb(0, 0, 0);
          font-family: Inter;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          
          .title {
            width: 100%;
            text-align: center;
            height: 60px;
            line-height: 60px;
            font-weight: 600;
            font-size: 20px;
          }
          .content {
            width: 100%;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding: 20px 0;
            text-align: center;
            .starting-at {
              font-size: 16px;
              font-weight: 600;
            }
            .money {
              font-size: 40px;
              font-weight: 600;
              position: relative;
              margin-left: 6px;
              top: 11px;
            }
            .per {
              margin-top: 12px;
              height: 20px;
              font-size: 14px;
              color: #4f4949;
            }
          }
          .des-t {
            margin-top: 12px;
          }
          .des {
            margin-top: 12px;
            width: 100%;
            min-height: 150px;
            text-align: center;
            .des-c {
              font-size: 20px;
              color: #4f4949;
            }
            
            .des-d {
              margin-top: 12px;
              font-size: 16px;
              font-weight: 600;
            }
          }
          .bill {
            text-align: center;
            width: 100%;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #4f4949;
          }
          .go-pricing {
            margin-top: 12px;
          }
        }
      }

      .foot-c {
        margin-top: 20px;
        font-size: 24px;

      }
    }
  }
}