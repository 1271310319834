.voterTitle {
  font-size: 24px;
  font-weight: 'bold';
  margin-top: 10px;
}
.voterContent {
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: "#fff";
}