.check-team-page {
  height: 100%;

  .bg-line {
    width: 100%;
    height: 24px;
    background: #283A53;
  }
  .check-team-main {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    .check-team-content {
      width: 586px;
      height: 366px;
      margin-top: 182px;
      font-weight: 400;
      font-family: Inter;
  
      .content-title {
        width: 100%;
        text-align: center;
  
        .name {
          height: 58px;
          font-size: 48px;
          line-height: 58px;
          .icon1 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: 20px;
          }
          .icon2 {
            display: inline-block;
            position: relative;
            top: 8px;
            left: -6px;
          }
        }
      }
  
      .content-des {
        margin-top: 71px;
        height: 120px;
        color: #000000;
        font-size: 20px;
        line-height: 24px;
      }
  
      .operate-content {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        .operate-btn {
          padding: 0;
          display: inline-block;
          width: 284px;
          height: 77px;
          line-height: 77px;
          text-align: center;
          border-radius: 20px;
          font-weight: 600x;
          font-size: 20px;
        }
      }
    }
  }
}