.polymofund-datarange {
  .ant-picker-separator {
    display: none !important;
  }
  .ant-picker-range {
    width: 100%;
    padding: 4px 6px;
  }
  input {
    height: 24px;
    font-size: 12px !important;
  }
}
