.sign-in-cms-page {
  height: calc(100vh);
  min-height: 700px;
  width: 100%;
  background: rgb(40, 58, 83);
  flex-direction: column;
  align-items: center;

  .sign-in-cms-card {
    // margin: 219px auto;
    margin-top: 219px;
    width: 496px;
    min-height: 395px;
    padding: 40px 36px;
    background: rgb(255, 255, 255);
    /* base */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .title {
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 30px;
      font-weight: 400;
      line-height: 150%;
    }

    .sing-in-form {
      .name {
        margin: 12px 0 6px 0;
      }

      .sign-operate {
        position: relative;
        top: 63px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}