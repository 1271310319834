.service-plan-page {
  width: 416px;

  .service-plan-name {
    .name {
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .no-join-team {
    margin-top: 16px;

    .account-content {
      .account-name {
        padding-top: 16px;
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        .due-day {
          margin-left: 12px;
        }
      }
    }

    .campaign-teams {
      margin-top: 31px;
      margin-bottom: 4px;
      color: rgb(0, 0, 0);
      font-family: Inter;
      font-size: 18px;
    }

    .team-item {
      margin-top: 12px;
      height: 22px;
      line-height: 22px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;

    }
    .dot {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 0 10px;
      background: #000;
      border-radius: 50%;
      position: relative;
      top: -4px;
    }

    .operate-content{
      margin-top: 50px;
      margin-bottom: 50px;
      .operate-btn {
        cursor: pointer;
        display: inline-block;
        padding: 5px 16px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgb(255, 255, 255);
        background: rgb(40, 58, 83);
        border-radius: 6px;
      }
      .btn-terminate {
        margin-left: 40px;
      }
    }
  }

  .create-account-content {
    margin-top: 32px;
    color: rgb(95, 95, 95);

    .des {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-bottom: 10px;
    }

    .service-plan-form {
      .name {
        margin-top: 12px;
      }

      .service-plan-operate {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 50px;
      }
    }
  }
}